import { EnumNames, ObjectParams } from '@/models/types'
import { paramsByIds } from '@/api/api'
import { removeValuesFromStart } from '@/utils/strings'

export enum StreetType {
  Calle = 1,
  Alto,
  Alameda,
  Avenida,
  Bulevar,
  Callejon,
  Camino,
  Carrera,
  Carretera,
  Corredera,
  Costanilla,
  Cuesta,
  Escalera,
  Glorieta,
  Pasaje,
  Paseo,
  Pista,
  Plaza,
  Plazoleta,
  Plazuela,
  Rambla,
  Rinconada,
  Ronda,
  Rotonda,
  Senda,
  Travesia,
  Via,
}

export const streetTypes: EnumNames = {
  [StreetType.Calle]: 'Calle',
  [StreetType.Alto]: 'Alto',
  [StreetType.Alameda]: 'Alameda',
  [StreetType.Avenida]: 'Avenida',
  [StreetType.Bulevar]: 'Bulevar',
  [StreetType.Callejon]: 'Callejon',
  [StreetType.Camino]: 'Camino',
  [StreetType.Carrera]: 'Carrera',
  [StreetType.Carretera]: 'Carretera',
  [StreetType.Corredera]: 'Corredera',
  [StreetType.Costanilla]: 'Costanilla',
  [StreetType.Cuesta]: 'Cuesta',
  [StreetType.Escalera]: 'Escalera',
  [StreetType.Glorieta]: 'Glorieta',
  [StreetType.Pasaje]: 'Pasaje',
  [StreetType.Paseo]: 'Paseo',
  [StreetType.Pista]: 'Pista',
  [StreetType.Plaza]: 'Plaza',
  [StreetType.Plazoleta]: 'Plazoleta',
  [StreetType.Plazuela]: 'Plazuela',
  [StreetType.Rambla]: 'Rambla',
  [StreetType.Rinconada]: 'Rinconada',
  [StreetType.Ronda]: 'Ronda',
  [StreetType.Rotonda]: 'Rotonda',
  [StreetType.Senda]: 'Senda',
  [StreetType.Travesia]: 'Travesia',
  [StreetType.Via]: 'Via',
}

export function removeStreetTypesFromStart(input: string): string {
  return removeValuesFromStart(input, streetTypes)
}

export const streetTypesForSelect: StreetType[] = [
  StreetType.Calle,
  StreetType.Avenida,
  StreetType.Alto,
  StreetType.Alameda,
  StreetType.Bulevar,
  StreetType.Callejon,
  StreetType.Camino,
  StreetType.Carrera,
  StreetType.Carretera,
  StreetType.Corredera,
  StreetType.Costanilla,
  StreetType.Cuesta,
  StreetType.Escalera,
  StreetType.Glorieta,
  StreetType.Pasaje,
  StreetType.Paseo,
  StreetType.Pista,
  StreetType.Plaza,
  StreetType.Plazoleta,
  StreetType.Plazuela,
  StreetType.Rambla,
  StreetType.Rinconada,
  StreetType.Ronda,
  StreetType.Rotonda,
  StreetType.Senda,
  StreetType.Travesia,
  StreetType.Via,
]

export enum CommunityType {
  Edificio = 1,
  Urbanizacion,
  CentroComercial,
  Residencial,
  Conjunto,
}

export const communityTypes: EnumNames = {
  [CommunityType.Edificio]: 'Edificio',
  [CommunityType.Urbanizacion]: 'Urbanización',
  [CommunityType.CentroComercial]: 'CentroComercial',
  [CommunityType.Residencial]: 'Residencial',
  [CommunityType.Conjunto]: 'Conjunto',
}

export enum GeoStatus {
  New = 1,
  Valid = 2,
  NotValid = 3,
}

export const geoStatuses: EnumNames = {
  [GeoStatus.New]: 'New',
  [GeoStatus.Valid]: 'Valid',
  [GeoStatus.NotValid]: 'Not valid',
}

export function statusHtml(status: GeoStatus): string {
  let className = 'text-secondary'
  if (status == GeoStatus.Valid) {
    className = 'text-success'
  } else if (status == GeoStatus.NotValid) {
    className = 'text-danger'
  } else if (status == GeoStatus.New) {
    className = 'text-orangered'
  }

  return `<b class="${className}">${geoStatuses[status] || ''}</b>`
}

export function isValid(status: GeoStatus): boolean {
  return status != GeoStatus.NotValid
}

export function isVerified(status: GeoStatus): boolean {
  return status == GeoStatus.Valid
}

export function validGeoStatusesQuery(): ObjectParams {
  return paramsByIds([GeoStatus.New, GeoStatus.Valid], 'statuses')
}
